<template lang="pug">
.custom-field.animated.fadeIn
  v-loading(:loader = 'loading')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    form.form-horizontal.animated.fadeIn(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      .card
        .card-header {{ header }}
        .card-body
          .row
            .col-lg-6
              fi-form-field(:label = '$t("order")')
                input.form-control.form-control-sm(v-model="customerFieldData.fieldOrder")
              fi-form-field(:label = '$t("customField")')
                fi-select(
                  v-model = "customerFieldData.customFieldId"
                  :options = "customFieldOptions"
                  required
                )
              fi-form-field(:label = '$t("customerSector")')
                fi-select(
                  v-model = "customerFieldData.customerSectorId"
                  :options = "optionsFromClassifier('sectors')"
                  required
                )
              fi-form-field(:label = '$t("required")')
                fi-switch(v-model   = 'customerFieldData.required')
              fi-form-field(:label = '$t("enabled")')
                fi-switch(v-model   = 'customerFieldData.enabled')
              fi-form-field(:label = '$t("enabledApplication")')
                fi-switch(v-model   = 'customerFieldData.enabledApplication')
              fi-form-field(:label="$t('enabledPortal')")
                fi-switch(v-model   = 'customerFieldData.enabledPortal')
          .row
            .col-lg
              button.btn.btn-primary.mb-3(
                type      = 'submit'
                :disabled = 'saving'
              ) {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
              | &nbsp;
              button.btn.btn-secondary.mb-3(
                :disabled      = 'saving'
                @click.prevent = 'onCancel'
              ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import CustomFieldValueList from '@/views/admin/customFields/CustomFieldValueList'

export default {
  name: 'customer-field',

  components: { CustomFieldValueList, FiSwitch, FiSelect, FiFormField },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    customerFieldData: {
      customFieldId: null,
      customerSectorId: null,
      enabled: false,
      enabledApplication: false,
      enabledPortal: false,
      fieldOrder: null
    }
  }),

  computed: {
    ...mapState('customerFields', ['customerField']),
    ...mapState('customFields', ['customFields']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    customFieldOptions () {
      return this.customFields.map(({ id, name }) => ({ value: id, text: name }))
    },
    isNew () {
      return !this.id
    },
    header () {
      if (this.isNew) {
        return this.$t('newTitle')
      } else {
        return this.$t('editTitle', { id: this.id })
      }
    },
    loading () {
      return [`customer:field:${this.id}:fetch`]
    },
    saving () {
      return this.$vueLoading.isLoading(this.isNew ? 'customer:field:save' : `customer:field:${this.id}:save`)
    }
  },

  watch: {
    id: {
      async handler (id) {
        await this.loadCustomerField({ id })
        this.customerFieldData = {
          ...this.customerFieldData,
          ...pick(this.customerField, Object.keys(this.customerFieldData))
        }
      },
      immediate: true
    }
  },

  created () {
    this.loadCustomFields()
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    ...mapActions('customerFields', ['saveCustomerField', 'loadCustomerField', 'updateCustomerField']),
    async onSubmit () {
      const { id, customerFieldData } = this
      if (this.isNew) {
        await this.saveCustomerField({ customerFieldData })
      } else {
        await this.updateCustomerField({ customerFieldData, id })
      }
      this.$router.push({ name: 'CustomerFieldList' })
    },
    onCancel () {
      this.$router.push({ name: 'CustomerFieldList' })
    }
  }
}
</script>


<i18n>
en:
  newTitle:           "New customer field"
  editTitle:          "Edit customer field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
et:
  newTitle:           "New customer field"
  editTitle:          "Edit customer field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
ru:
  newTitle:           "New customer field"
  editTitle:          "Edit customer field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
</i18n>
