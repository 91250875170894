<template lang="pug">
.customer-field-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      fi-table(
        :fields          = 'fields'
        :items          = 'sortClassifiers'
        loader          = 'customer:fields:fetch'
        :empty-label    = '$t("notFound")'
        draggable-class = '.customer-field-list-item'
        draggable
        @drag           = 'updateDragged'
      )
        template(#custom-row)
          tr(v-if = '$v.values.$dirty')
            td(:colspan = 'fields.length')
              button.btn.btn-primary(@click = 'saveOrder') {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("customer:fields:save")')
        template(v-slot = '{ items }')
          customer-field-list-item(
            v-for          = 'item in items'
            :key           = 'item.fieldOrder'
            :customer-field = 'item'
            :custom-field   = 'customFieldById(item.customFieldId)'
          )

</template>

<script>
import FiTable from '@/components/FiTable'
import CustomerFieldListItem from '@/views/admin/customer/CustomerFieldListItem'
import { mapActions, mapGetters, mapState } from 'vuex'
import { validation } from '@/validation'

export default {
  name: 'customer-field-list',

  components: { CustomerFieldListItem, FiTable },

  mixins: [validation],

  data: () => ({
    values: []
  }),

  computed: {
    ...mapState('customerFields', ['customerFields']),
    ...mapGetters('customFields', ['customFieldById']),
    fields () {
      return [
        {
          key: 'fieldOrder',
          label: this.$t('order')
        },
        {
          key: 'key',
          label: this.$t('key')
        },
        {
          key: 'name',
          label: this.$t('name')
        },
        {
          key: 'required',
          label: this.$t('required')
        },
        {
          key: 'enabled',
          label: this.$t('enabled')
        },
        {
          key: 'enabledPortal',
          label: this.$t('enabledPortal')
        },
        {
          key: 'enabledApplication',
          label: this.$t('enabledApplication')
        },
        {
          key: 'customerSector',
          label: this.$t('customerSector')
        },
        {}
      ]
    },
    sortClassifiers () {
      const list = JSON.parse(JSON.stringify(this.values))
      list.forEach((current) => {
        current.customName = this.customFieldById(current.customFieldId).name
      })
      const sortList = list.sort(function (a, b) {
        const nameA = a.customName
        const nameB = b.customName
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    }
  },

  validations: {
    values: {}
  },

  async created () {
    await this.loadCustomFields()
    await this.loadCustomerFields()
    this.values = [...this.customerFields]
  },

  methods: {
    ...mapActions('customerFields', ['loadCustomerFields', 'updateCustomerFields']),
    ...mapActions('customFields', ['loadCustomFields']),
    updateDragged (items) {
      this.$v.values.$model = items.filter(Boolean).map((item, index) => ({ ...item, fieldOrder: index + 1 }))
    },
    async saveOrder () {
      await this.updateCustomerFields({ customerFieldsData: this.values })
      this.$v.$reset()
    }
  }
}
</script>


<i18n>
en:
  title:              "Customer fields"
  newLink:            "+ New customer field"
  order:              "Order"
  key:                "Key"
  name:               "Name"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  notFound:           "No customer fields defined"
  required:           "Required"
et:
  title:              "Customer fields"
  newLink:            "+ New customer field"
  order:              "Order"
  key:                "Key"
  name:               "Name"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  notFound:           "No customer fields defined"
  required:           "Required"
ru:
  title:              "Customer fields"
  newLink:            "+ New customer field"
  order:              "Order"
  key:                "Key"
  name:               "Name"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  notFound:           "No customer fields defined"
  required:           "Required"
</i18n>
